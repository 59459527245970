@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/fonts/fonts/helvetica/HelveticaNeueBold.otf') format('otf'),
       url('../public/fonts/fonts/helvetica/HelveticaNeueBold.otf')  format('woff'),
       url('../public/fonts/fonts/helvetica/HelveticaNeueBold.otf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'danymeka';
  src: url('../public/fonts/fonts/danymeka/Danymeka-lxx2D.otf') format('otf'),
       url('../public/fonts/fonts/danymeka/Danymeka-lxx2D.otf')  format('woff'),
       url('../public/fonts/fonts/danymeka/Danymeka-lxx2D.otf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'delistan';
  src: url('../public/fonts/fonts/delistan/Delistan-nAAm4.ttf') format('otf'),
       url('../public/fonts/fonts/delistan/Delistan-nAAm4.ttf')  format('woff'),
       url('../public/fonts/fonts/delistan/Delistan-nAAm4.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}



/* @font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
       url('/fonts/HelveticaNeue-Bold.woff') format('woff'),
       url('/fonts/HelveticaNeue-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
} */

@media screen and (max-width: 500px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }  

  #careers {
    width: 100%;
  }  
}

@media screen and (max-width: 1200px) {
  header .intro-text-secondary {    
    font-size: 2.38rem !important;
  }

  .intro h1 {
    font-size: 6rem;
  }
  
}

@media screen and (max-width: 1080px) {
  header .intro-text {
    margin-top: 30%;
    /* padding-top: 70% !important; */
    /* padding-bottom: 10px; */
    text-align: left;
  }
  
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); 
  pointer-events: none; 
}
