.file-input-container {
position: relative;
overflow: hidden;
display: inline-block;
}

.file-input-container input[type="file"] {
    font-size: 100px; 
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0; 
}

.file-input-button {
  background-color: #0055b1; /* Bootstrap primary color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-input-button:hover {
  background-color: #002b58; /* Darken the color on hover for feedback */
}

/* Additional style for the icon */
.file-icon {
  margin-right: 10px;
}

/* Styling for the file name display and clear button */
.file-display {
  margin-top: 10px;
  font-size: 14px;
}

.clear-button {
  background-color: #dc3545; /* Bootstrap danger color */
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-button:hover {
  background-color: #c82333; /* Darken the color on hover */
}


/* .file-input-button {
    display: inline-block;
    padding: 10px 20px;
    text-align: center;
    background-color: #f8f8f8;
    cursor: pointer;
} */

/* .modalHeader {
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    padding: 0; 
    background-color: #fff; 
}

.modalBody {
    
    overflow-y: auto;
    overflow-x: hidden;
} */



.modalBody h2 {
    font-size: 32px;
  }
  


.postitle{
    color: orange;
    margin-bottom: 0px !important;    
    margin-top:20px !important;
}

.postitle + h3 {
    margin-top: 0px !important; 
}

.close-btn {
    position: absolute;
    left: 0 !important;  /* Align to the left */
    top: 0 !important;    /* Align to the top */
    background: transparent;
    border: none;
    color: red;
}



.close-btn i.fa {
    font-size: 24px;  /* Adjust this value as needed */
}

.close-icon {
  width: 40px !important;
  height: 40px !important;
  position: relative;
  display: inline-block;
  
}

.close-icon:before, .close-icon:after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0;
  background-color: black;
}

.close-icon:before {
  transform: rotate(45deg);
}

.close-icon:after {
  transform: rotate(-45deg);
}