body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 54px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-link img {
  width: 60px;
  height: auto;
  margin-right: 10px;
}

.career-detail-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.career-detail-modal .modal-content-wrapper {
  position: relative; 
  display: inline-block; 
}

.career-detail-modal .container {
  background: white;
  padding: 70px;
  padding-right: 90px;
  border-radius: 5px;
  position: relative;
  text-align: left;
  max-height: 80vh; 
  max-width: 70vh;
  overflow-y: auto; 
  text-align: left;
  box-sizing: border-box;
}

.career-detail-modal .close-btn {
  position: absolute;
  top: 5px;
  left: 5px; 
  cursor: pointer;
  z-index: 2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.career-detail-modal.show {
  animation: fadeIn 0.5s forwards;
}

.career-detail-modal.hide {
  animation: fadeOut 0.5s forwards;
}
